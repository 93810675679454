import { IUser } from '@util/types'
import Config from './config'
import * as Session from './session'

export function track(user: IUser, code: string, properties: any = {}) {
  return trackWithActorId(user.tracking_id, code, properties)
}

export function trackAnonymous(code: string, properties: any = {}) {
  return trackWithActorId(Session.ID, code, properties)
}

function trackWithActorId(actorId: string, code: string, properties: any = {}) {
  post('events', {
    code,
    source: 'my-web-app',
    actor_id: actorId,
    properties
  })
}

async function post(path: string, data: object) {
  if (!Config.statsRoot) {
    return
  }

  const res = await fetch(Config.statsRoot + path, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })

  if (res.ok) {
    return res.json()
  }
}
