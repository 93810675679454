import * as Api from '@util/api'
import { IUser } from '@util/types'
import { useEffect, useState } from 'react'

/**
 * useUserSync
 *
 * Drop-in replacement for useState for a user, but ensures
 * that the use stays up-to-date with changes on the server by
 * synchronizing state on mount, and when the page regains focus.
 *
 * NOTE: We can make this a lot better. We could detect when the user
 * state changes locally, and cancel the sync and redo later so we don't
 * get data overwritten. We could also detect *what* has changed, and apply
 * just those changes.
 *
 */
export default function useUserSync(initialUser: IUser) {
  const [user, setUser] = useState(initialUser)

  async function sync() {
    const res = await Api.getUserIfModified(Api.userClient(user), {
      lastModifiedTimestamp: user.last_modified_timestamp || 0
    })

    if (res.type === 'ok' && res.data) {
      setUser(res.data)
    }
  }

  // Synchronize on mount
  useEffect(() => {
    sync()
  }, [])

  // Synchronize whenever the app regains focus.
  // NOTE: The app is only considered to regain focus if the tab is
  // activated, or the window returns from minimized. Switching focus
  // from another window won't trigger this.
  useEffect(() => {
    function handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        sync()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return [user, setUser] as const
}
