interface IProps {
  type?: 'text' | 'password' | 'email'
  value: string
  onChangeValue: (value: string) => void
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
}

const TextInput: React.FC<IProps> = (props) => {
  return (
    <input
      type={!!props.type ? props.type : 'text'}
      {...props.inputProps}
      value={props.value}
      onChange={(event) => props.onChangeValue(event.currentTarget.value)}
    />
  )
}

export default TextInput
