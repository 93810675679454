import * as Api from '@mealime/mealime-api-client'
import Config from './config'
import * as Session from './session'

export * from '@mealime/mealime-api-client'

export function anonymousClient(): Api.Client.Anonymous {
  return new Api.Client.Anonymous({
    ...BASE_OPTIONS,
    clientId: Session.ID,
    baseUrl: Config.apiRoot
  })
}

export function userClient(user: Api.IUser): Api.Client.Authenticated {
  return new Api.Client.Authenticated({
    ...BASE_OPTIONS,
    clientId: user.tracking_id,
    authToken: user.auth_token
  })
}

export function authenticatedClient(
  authToken: string
): Api.Client.Authenticated {
  return new Api.Client.Authenticated({
    ...BASE_OPTIONS,
    clientId: Session.ID,
    authToken
  })
}

const BASE_OPTIONS = {
  fetch,
  source: 'my-web',
  baseUrl: Config.apiRoot
}
