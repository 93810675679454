import { useContext } from 'react'
import { OverlayContext } from './context-provider'

export default function useOverlay() {
  const overlayContext = useContext(OverlayContext)

  return {
    show: (content: JSX.Element) => {
      overlayContext.dispatch({ type: 'show', content })
    },
    hide: () => {
      overlayContext.dispatch({ type: 'hide' })
    },
    isVisible: overlayContext.state.isVisible
  }
}
