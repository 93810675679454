import styled from '@emotion/styled'
import * as Style from '@util/style'
import useDismissableRef from '@util/ui/use-dismissable-ref'
import { useRef, useState } from 'react'

interface IAction {
  icon?: JSX.Element
  label: string
  onClick: () => void
}

interface IProps {
  label: JSX.Element | string
  alignMenu?: 'left' | 'right'
  actions?: IAction[]
  styleWrapper?: React.CSSProperties
  buttonType?: 'text'
}

const Dropdown: React.FC<IProps> = ({
  label,
  alignMenu: align = 'left',
  actions,
  styleWrapper,
  buttonType
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useDismissableRef(ref, () => {
    setIsOpen(false)
  })

  const handleItemPress = (actionClick: () => void) => {
    setIsOpen(false)
    actionClick()
  }

  function renderButton() {
    if (buttonType === 'text') {
      return (
        <ButtonText type="button" onClick={() => setIsOpen(!isOpen)}>
          {label}
        </ButtonText>
      )
    }

    return (
      <Button type="button" onClick={() => setIsOpen(!isOpen)}>
        {label}
      </Button>
    )
  }

  return (
    <div ref={ref} style={{ position: 'relative', ...styleWrapper }}>
      {renderButton()}
      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            // Align the menu to either the left or right of the Button
            left: align === 'left' ? 0 : 'auto',
            right: align === 'right' ? 0 : 'auto',
            zIndex: 1,
            borderRadius: 8,
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            backgroundColor: '#fff',
            boxShadow: '0 4px 12px -4px rgba(0, 0, 0, 0.25)'
          }}
        >
          {actions?.map((action, index) => (
            <Item
              key={index}
              type="button"
              onClick={() => handleItemPress(action.onClick)}
            >
              {action.icon && (
                <div
                  style={{
                    color: Style.COLOR.GREY_600,
                    marginRight: '0.75rem'
                  }}
                >
                  {action.icon}
                </div>
              )}
              <div style={{ whiteSpace: 'nowrap' }}>{action.label}</div>
            </Item>
          ))}
        </div>
      )}
    </div>
  )
}

const Button = styled.button({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  verticalAlign: 'middle',
  color: Style.COLOR.GREY_700,
  height: '2rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  borderRadius: '0.25rem',

  ':hover, :focus': {
    color: Style.COLOR.GREY_800,
    backgroundColor: Style.COLOR.GREY_200
  },

  ':active': {
    backgroundColor: Style.COLOR.GREY_300
  }
})

const ButtonText = styled.button({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '2rem',
  color: Style.COLOR.GREY_700,
  fontSize: '1rem',
  backgroundColor: 'transparent',
  border: 'none',
  textAlign: 'center',
  verticalAlign: 'middle',
  lineHeight: 1.5,
  paddingLeft: 0,
  paddingRight: 0,
  transition: 'color 0.15s ease, opacity 0.1s ease',

  ':hover, :focus': {
    color: Style.COLOR.GREY_900
  },

  ':active': {
    opacity: 0.8
  }
})

const Item = styled.button({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  border: 'none',
  paddingTop: '0.75rem',
  paddingBottom: '0.75rem',
  paddingLeft: '1.5rem',
  paddingRight: '5rem',
  color: Style.COLOR.GREY_800,
  backgroundColor: 'transparent',
  fontWeight: 500,
  verticalAlign: 'middle',
  lineHeight: 1,

  ':hover, :focus': {
    backgroundColor: Style.COLOR.GREY_100
  },

  ':active': {
    backgroundColor: Style.COLOR.GREY_300
  },

  '& + &': {
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: Style.COLOR.GREY_200
  }
})

export default Dropdown
