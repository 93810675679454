import { useState } from 'react'

export default function useTextField(initialValue: string) {
  const [value, setValue] = useState(initialValue)
  const [error, setError] = useState<string | undefined>()

  return {
    value,
    error,
    set: (newValue: string) => setValue(newValue),
    setError: (newError: string) => setError(newError),
    clear: () => setValue(''),
    clearError: () => setError(undefined)
  }
}
