export const WEIGHT_MEDIUM = 500
export const WEIGHT_SEMIBOLD = 600
export const WEIGHT_BOLD = 700

export const FONT_FAMILY_SAN_SERIF = "'Inter', sans-serif"
export const FONT_SIZE_REGULAR = '1rem'

export const GUTTER_WIDTH = '1rem'

export const COLOR = {
  GREEN_200: '#E1F5D5',
  GREEN_500: '#5ebd21',
  GREEN_700: '#4d8f22',
  YELLOW_500: 'rgb(245, 222, 125)',
  GREY_900: '#202020',
  GREY_800: '#464646',
  GREY_700: '#6d6d6d',
  GREY_600: '#B4B4B4',
  GREY_300: '#E2E6E9',
  GREY_200: 'rgb(238, 238, 238)',
  GREY_100: '#f8f8f8',
  RED_500: '#E4493E'
}
