import { useEffect, useState } from 'react'

/**
 * useDynamicState
 *
 * Provides the same api as `useState`, but updates the value
 * if the parameter value changes. This allows the state to be
 * updated externally, and the value to update as you would expect.
 */
export default function useDynamicState<T>(initialValue: T) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return [value, setValue] as const
}
