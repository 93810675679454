import styled from '@emotion/styled'
import * as Style from '@util/style'

export default styled.div({
  position: 'fixed',
  bottom: Style.GUTTER_WIDTH,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center'
})
