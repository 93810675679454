import styled from '@emotion/styled'
import * as Style from '@util/style'

export default styled.div({
  fontSize: '2rem',
  fontWeight: 700,
  userSelect: 'none',
  letterSpacing: -0.25,
  color: Style.COLOR.GREY_800
})
