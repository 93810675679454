import styled from '@emotion/styled'
import { mediaBreakpointUp } from '@util/ui'

interface IProps {
  children: React.ReactNode
  style?: React.CSSProperties
}

const Container: React.FC<IProps> = ({ children, style }) => {
  return <StyledContainer style={{ ...style }}>{children}</StyledContainer>
}

const StyledContainer = styled.div({
  position: 'relative',
  width: '100%',
  paddingRight: 16,
  paddingLeft: 16,
  marginRight: 'auto',
  marginLeft: 'auto',

  [mediaBreakpointUp('sm')]: {
    maxWidth: 540
  },

  [mediaBreakpointUp('md')]: {
    maxWidth: 768
  },

  [mediaBreakpointUp('lg')]: {
    maxWidth: 960
  },

  [mediaBreakpointUp('xl')]: {
    maxWidth: 1140
  }
})

export default Container
