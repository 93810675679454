import Modal from '@lib/overlay'
import { OverlayContextProvider } from '@lib/overlay/context-provider'
import useOnMount from '@lib/use-on-mount'
import * as WebRouter from '@lib/web-router'
import * as Api from '@util/api'
import AuthToken from '@util/auth-token'
import * as Errors from '@util/errors'
import * as Paths from '@util/paths'
import * as Profile from '@util/profile'
import 'normalize.css'
import { useState } from 'react'
import ErrorBoundary from './error-boundary'
import GlobalStyles from './global-styles'
import Loading from './loading'
import * as PathManagement from './path-management'

type IState =
  | { type: 'loading' }
  | { type: 'ok'; initialRoute: WebRouter.IRoute }

const App: React.FC = () => {
  const [state, setState] = useState<IState>({ type: 'loading' })

  Errors.init()

  useOnMount(async () => {
    const route = await getInitialRoute()
    setState({ type: 'ok', initialRoute: route })
  })

  function renderContent() {
    switch (state.type) {
      case 'loading':
        return <Loading />
      default:
        return <WebRouter.Router initialRoute={state.initialRoute} />
    }
  }

  return (
    <ErrorBoundary>
      <OverlayContextProvider>
        <Modal />
        <GlobalStyles />
        {renderContent()}
      </OverlayContextProvider>
    </ErrorBoundary>
  )
}

async function getInitialRoute(): Promise<WebRouter.IRoute> {
  const authToken = AuthToken.get()

  PathManagement.setPath()

  const initialPath = PathManagement.getCurrentPath()

  PathManagement.clear()

  const route = WebRouter.makeRoute

  switch (initialPath) {
    case Paths.SIGN_IN:
      return route(import('@scenes/registration/web/sign-in'), {})
    case Paths.SIGN_UP:
      return route(import('@scenes/registration/web/menu'), {
        initialProfile: Profile.makeEmpty()
      })
    case Paths.RESET_PASSWORD:
      return route(import('@scenes/registration/web/reset-password'), { email: '' })
    case Paths.AFFILIATE_REGISTRATION:
      return route(import('@scenes/registration/affiliate/menu'), {})
    case Paths.ORGANIZATION_REGISTRATION:
      return route(import('@scenes/registration/organization/offer'), {})
    case Paths.CAMPAIGN_REGISTRATION:
      return route(import('@scenes/registration/campaign/offer'), {})
  }

  if (authToken) {
    const res = await Api.getUser(Api.authenticatedClient(authToken))

    if (res.type === 'ok') {
      const user = res.data
      return route(import('@scenes/home'), { user })
    } else {
      console.log('Failed to sign in using saved auth token.')
      AuthToken.clear()
      return route(import('@scenes/registration/web/sign-in'), {})
    }
  } else {
    return route(import('@scenes/registration/web/welcome'), {})
  }
}

export default App
