import { IUserRecipe } from '@mealime/mealime-api-client'
import * as Style from '@util/style'
import emptyPlateBackground from './empty-plate-background.jpg'
import emptyPlate from './empty-plate.png'

interface IProps {
  recipe: IUserRecipe
  letterSize: number | string
  size: number
  style?: React.CSSProperties
  onClick?: () => void
}

const UserRecipeImage: React.FC<IProps> = (props) => {
  function getBackgroundColor() {
    return getRandomColorFromString(props.recipe.uid)
  }

  function getInitial() {
    return props.recipe.name[0]
  }

  function getImageUrl() {
    const { recipe, size } = props

    // The thumbnail image is 300x300. Assuming 2x resolution,
    // we show the thumbnail until the size is >150px.
    return size > 150
      ? recipe.presentation_image_url
      : recipe.thumbnail_image_url
  }

  function renderPlaceholder() {
    return (
      <div
        onClick={props.onClick}
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '100%',
          height: 0,
          paddingTop: '100%', // padding top % is based on the width of the element! Weird but true. Creates a 1:1 aspect ratio.
          overflow: 'hidden',
          userSelect: 'none',
          cursor: !!props.onClick ? 'pointer' : 'default',
          ...props.style
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%',
            backgroundImage: `url(${emptyPlateBackground})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%',
            opacity: 0.25,
            backgroundColor: getBackgroundColor()
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%',

            backgroundImage: `url(${emptyPlate})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            fontSize: props.letterSize,
            fontWeight: 900,
            color: Style.COLOR.GREY_800
          }}
        >
          {getInitial()}
        </div>
      </div>
    )
  }

  function renderImage(url: string) {
    return (
      <img
        onClick={props.onClick}
        src={url}
        width={props.size}
        height={props.size}
        alt={props.recipe.name}
        style={{
          maxWidth: '100%',
          height: 'auto',
          backgroundColor: Style.COLOR.GREY_200,
          userSelect: 'none',
          cursor: !!props.onClick ? 'pointer' : 'default',
          ...props.style
        }}
      />
    )
  }

  const imageUrl = getImageUrl()

  return !!imageUrl ? renderImage(imageUrl) : renderPlaceholder()
}

export function getRandomColorFromString(str: string): string {
  const code = hashCode(str)
  const c = (code & 0x00ffffff).toString(16).toUpperCase()
  return '#' + ('00000'.substring(0, 6 - c.length) + c)
}

function hashCode(str: string): number {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

export default UserRecipeImage
