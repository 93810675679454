import styled from '@emotion/styled'
import * as Style from '@util/style'

export default styled.button({
  display: 'flex',
  alignItems: 'center',
  color: Style.COLOR.GREY_700,
  fontSize: '1rem',
  backgroundColor: Style.COLOR.GREY_200,
  border: 'none',
  textAlign: 'center',
  verticalAlign: 'middle',
  lineHeight: 1.5,
  borderRadius: '.25rem',
  transition: 'background-color 0.15s ease',
  height: '2rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',

  ':hover, :focus, :active': {
    color: Style.COLOR.GREY_800,
    backgroundColor: Style.COLOR.GREY_300
  }
})
