import Cookies from 'js-cookie'

export function getCurrentPath(): string {
  const path = document.location.pathname

  if (path[0] === '/' && path.length > 2) {
    return path.split('/')[1]
  } else {
    return path
  }
}

export function setPath() {
  const path = document.location.pathname
  if (path.includes('business')) {
    setOrganization()
  } else if (path.includes('campaign')) {
    setCampaign(path)
  }
}

export function setOrganization() {
  const path = document.location.pathname
  if (path.split('/').length - 1 === 2) {
    const org = path.split('/').pop() as string
    Cookies.set(
      'organization',
      {
        code: org
      },
      { expires: 15 }
    )
  }
}

export function setCampaign(path: string) {
  const params = new URLSearchParams(document.location.search)

  const freeTrial: boolean = params.get('t') === 'true' ? true : false
  const trialDays: number = parseInt(params.get('d') || '3')

  Cookies.set(
    'campaign',
    {
      freeTrial: freeTrial,
      trialDays: trialDays
    },
    { expires: 15 }
  )
}

export function clear() {
  window.history.replaceState(null, document.title, '/')
}
