import { keyframes } from '@emotion/react'
import * as Style from '@util/style'

interface IProps {
  label?: string
}

const Loading: React.FC<IProps> = ({ label }) => {
  const spin = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
`

  const circle = keyframes`
  0%, 25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%, 75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
`

  return (
    <div
      style={{
        display: 'grid',
        height: '100%',
        backgroundColor: Style.COLOR.GREY_100,
        placeItems: 'center',
        paddingBottom: '4rem' // offset to optical center.
      }}
    >
      <div
        style={{ display: 'inline-block', textAlign: 'center', width: '100%' }}
      >
        <svg
          css={{
            animationName: spin,
            animationDuration: '1.4s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            maxWidth: '4rem'
          }}
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            css={{
              animationName: circle,
              animationTimingFunction: 'ease-in-out',
              animationFillMode: 'both',
              animationIterationCount: 'infinite',
              animationDuration: '1.4s',
              display: 'block',
              fill: 'transparent',
              stroke: Style.COLOR.GREY_600,
              strokeLinecap: 'round',
              strokeDasharray: 283,
              strokeDashoffset: 280,
              strokeWidth: 10,
              transformOrigin: '50% 50%'
            }}
            cx="50"
            cy="50"
            r="45"
          />
        </svg>
        {!!label && (
          <div
            style={{
              textAlign: 'center',
              marginLeft: '0.75rem',
              marginTop: '1rem',
              color: Style.COLOR.GREY_700
            }}
          >
            {label}
          </div>
        )}
      </div>
    </div>
  )
}

export default Loading
