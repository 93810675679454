import styled from '@emotion/styled'
import * as Style from '@util/style'
import { Title } from '..'
import { mediaBreakpointUp } from '../breakpoints'
import appStore from './app-store.png'
import googlePlay from './google-play.png'

const MobileBlockNotice: React.FC = () => {
  const APP_STORE_URL =
    'https://itunes.apple.com/us/app/mealime-healthy-meal-plans/id1079999103'
  const GOOGLE_STORE_URL =
    'https://play.google.com/store/apps/details?id=com.mealime&hl=en'

  return (
    <Wrapper>
      <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
        <Title>Download the App</Title>
        <div
          style={{
            fontSize: 18,
            fontWeight: 500,
            color: Style.COLOR.GREY_700,
            lineHeight: 1.5,
            maxWidth: '36ch'
          }}
        >
          <p style={{ marginTop: '0.75rem' }}>
            Looking to use Mealime on your mobile device? Head over to the{' '}
            <Link href={APP_STORE_URL}>App Store</Link> or{' '}
            <Link href={GOOGLE_STORE_URL}>Google Play</Link>.
          </p>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '0.5rem',
              marginTop: '4rem',
              marginBottom: '2rem',
              marginLeft: '1rem',
              marginRight: '1rem'
            }}
          >
            <Link href={APP_STORE_URL}>
              <StoreImage
                src={appStore}
                alt="Apple app store download banner"
              />
            </Link>
            <Link href={GOOGLE_STORE_URL}>
              <StoreImage src={googlePlay} alt="Google play download banner" />
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'grid',
  backgroundColor: 'white',
  placeItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 9999,
  paddingLeft: '1rem',
  paddingRight: '1rem',

  [mediaBreakpointUp('md')]: {
    display: 'none'
  }
})

const Link = styled.a({
  color: Style.COLOR.GREEN_500,
  transition: 'color 0.15s ease, backgroundColor 0.15s ease',

  ':hover': {
    color: Style.COLOR.GREEN_700
  }
})

const StoreImage = styled.img({
  maxWidth: '100%',
  height: 'auto',
  opacity: 0.8,
  transition: 'opacity 0.2s ease',

  ':hover': {
    opacity: 1
  }
})

export default MobileBlockNotice
