import { useEffect } from 'react'

let currentAction: () => void = () => {}

let isInitialized = false

function initialize() {
  if (isInitialized) {
    return
  }

  isInitialized = true

  window.history.pushState(null, document.title, document.location.href)

  window.addEventListener('popstate', () => {
    window.history.pushState(null, document.title, document.location.href)
    currentAction()
  })
}

export default function useBackButtonAction(action: () => void) {
  useEffect(() => {
    initialize()
    const previousAction = currentAction
    currentAction = action
    return () => {
      currentAction = previousAction
    }
  }, [action])
}
