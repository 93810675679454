import { useEffect } from 'react'

// Use this in place of useEffect with an empty dependency list

const useOnMount = (fn: () => void | Promise<void>) => {
  useEffect(() => {
    fn()
  }, [])
}

export default useOnMount
