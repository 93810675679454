import { IConfig } from './types'

export const config: IConfig = {
  stripePublishableKey: 'pk_live_m4VIY9Wed7VnX69kkQBdYPQW',
  apiRoot: 'https://api.mealime.com/api/v2/',
  statsRoot: undefined,
  publishedRecipesRoot: 'https://cdn-recipes.mealime.com',
  sentryDsn:
    'https://6e134260c222448380940f3e3bbd10b0@o34341.ingest.sentry.io/5377736'
}
