import * as Sentry from '@sentry/browser'
import Config from './config'

// REACT_APP_SENTRY_RELEASE is the git commit hash, set by Vercel during deploy

export const init = () => {
  if (
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_SENTRY_RELEASE &&
    Config.sentryDsn
  ) {
    Sentry.init({
      dsn: Config.sentryDsn,
      release: process.env.REACT_APP_SENTRY_RELEASE
    })
  }
}
