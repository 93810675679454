type IBreakpoint = 'sm' | 'md' | 'lg' | 'xl'

export const breakpoints: { [key in IBreakpoint]: number } = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

// Media queries for a given breakpoint and up
export const mediaBreakpointUp = (size: IBreakpoint) => {
  return `@media (min-width: ${breakpoints[size]}px)`
  /**
   *  sizes: sm, md, lg, xl
   */
}

// Media queries for targeting a single segment of screen sizes
// using the minimum and maximum breakpoint widths.
export const mediaBreakpointOnly = (size: IBreakpoint) => {
  // get the index of the provided size
  const sizeIndex = Object.keys(breakpoints).indexOf(size)
  // get the key name of the following breakpoint
  const nextBreakpoint = Object.keys(breakpoints)[sizeIndex + 1] as IBreakpoint
  // get the key's value and calculate the max width. The value needs to be
  // just under the next breakpoint to avoid another breakpoint overwriting the rule.
  const nextBreakPointMaxWidth: number = breakpoints[nextBreakpoint] - 0.02

  switch (size) {
    case 'sm':
    case 'md':
    case 'lg':
      // Example of the 'sm' @media (min-width: 576px) and (max-width: 767.98px) { ... }
      return `@media (min-width: ${breakpoints[size]}px) and (max-width: ${nextBreakPointMaxWidth}px)`

    case 'xl':
      // Extra large devices (large desktops, 1200px and up)
      return `@media (min-width: ${breakpoints[size]}px)`
  }
  /**
   *  Sizes: sm: 576,
   *         md: 768,
   *         lg: 992,
   *         xl: 1200
   */
}
