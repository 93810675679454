import FIXTURES from './fixtures'
import type { IDislike, IProfile, IRecipeRestriction } from './types'

const US_UNIT_FAMILY_ID = 2
const CLASSIC_RECIPE_TYPE_ID = 1

export function makeEmpty(): IProfile {
  return {
    recipe_type_id: CLASSIC_RECIPE_TYPE_ID,
    unit_family_id: US_UNIT_FAMILY_ID,
    recipe_restriction_ids: [],
    dislike_ids: [],
    serving_count: 2
  }
}

export const RECIPE_TYPES = FIXTURES.recipe_types.sort(
  (a, b) => a.position - b.position
)

export function feasibleRecipeRestrictions(
  profile: IProfile
): IRecipeRestriction[] {
  const all = FIXTURES.recipe_restrictions.sort(
    (a, b) => a.position - b.position
  )

  const recipeType = FIXTURES.recipe_types.find(
    (t) => t.id === profile.recipe_type_id
  )

  if (!recipeType) {
    return all
  }

  // Include only restrictions that are included in the allow restrictions
  // by the user's recipe type.
  return all.filter((res) =>
    recipeType.allowed_restriction_ids.includes(res.id)
  )
}

export function feasibleDislikes(
  profile: IProfile,
  options: { featuredOnly: boolean }
): IDislike[] {
  let dislikes = FIXTURES.dislikes.sort((a, b) => a.name.localeCompare(b.name))

  if (options.featuredOnly) {
    dislikes = dislikes.filter((d) => d.is_featured)
  }

  return dislikes.filter(
    (dislike) =>
      dislikeAllowsRecipeType(dislike, profile.recipe_type_id) &&
      dislikeAllowsAllRecipeRestrictions(
        dislike,
        profile.recipe_restriction_ids
      )
  )
}

// The recipe type must be allowed by the dislike
function dislikeAllowsRecipeType(dislike: IDislike, typeId: number): boolean {
  return !typeId || dislike.allowed_recipe_type_ids.includes(typeId)
}

// Every recipe restriction must be allowed by the dislike.
function dislikeAllowsAllRecipeRestrictions(
  dislike: IDislike,
  restrictionIds: number[]
): boolean {
  for (const id of restrictionIds) {
    if (!dislike.allowed_recipe_restriction_ids.includes(id)) {
      return false
    }
  }

  return true
}

export const UNIT_FAMILIES = FIXTURES.unit_families

interface IServingOption {
  count: number
  body: string
}

export const SERVING_OPTIONS: IServingOption[] = [
  { count: 2, body: 'for two, or one with leftovers' },
  { count: 4, body: 'for four, or two-three with leftover' },
  { count: 6, body: 'for a family of 5+' }
]
