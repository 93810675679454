import styled from '@emotion/styled'
import * as Style from '@util/style'

export default styled.button<{
  small?: boolean
  large?: boolean
  shadow?: boolean
}>(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2.5rem',
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    letterSpacing: 0.33,
    color: 'white',
    backgroundColor: Style.COLOR.GREEN_500,
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    transition: 'background-color 0.15s ease, opacity 0.2s ease',

    ':hover, :focus, :active': {
      backgroundColor: '#57b01e'
    },

    ':disabled': {
      backgroundColor: Style.COLOR.GREY_200,
      color: Style.COLOR.GREY_700,
      opacity: 0.8,
      cursor: 'not-allowed'
    },

    ':hover:disabled': {
      opacity: 0.8
    },

    ':active': {
      opacity: 0.5
    }
  },
  (props) => [
    props.small && {
      height: '2rem',
      fontSize: 16,
      fontWeight: 400,
      paddingLeft: '1rem',
      paddingRight: '1rem'
    },
    props.large && {
      height: '3rem',
      fontSize: 18,
      fontWeight: 500,
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      borderRadius: '0.5rem'
    },
    props.shadow && {
      boxShadow: '0px 12px 6px -10px rgba(66, 147, 70, 0.654)',
      ':disabled': {
        boxShadow: 'none'
      }
    }
  ]
)
