import { IContextAction, IContextState } from './types'

export const contextReducer = (
  state: IContextState,
  action: IContextAction
): IContextState => {
  switch (action.type) {
    case 'show':
      return {
        ...state,
        isVisible: true,
        content: action.content
      }
    case 'hide':
      return {
        ...state,
        isVisible: false
      }

    default:
      return state
  }
}
