import * as Style from '@util/style'
import { ButtonDefault } from '@util/ui'
import * as React from 'react'

interface IState {
  error?: {
    message: string
    stack?: string
  }
}

export default class ErrorBoundary extends React.Component<{}, IState> {
  state: IState = {}

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error: { message: error.message, stack: error.stack } }
  }

  componentDidCatch(error: any, _errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log('Caught an error', error)
  }

  handleReload = () => {
    document.location.reload()
  }

  render() {
    const { error } = this.state
    if (error) {
      return (
        <div
          css={{
            display: 'grid',
            placeItems: 'center',
            maxWidth: 400,
            fontFamily: "'Inter', sans-serif",
            lineHeight: 1.3,
            paddingTop: '2rem',
            paddingBottom: '5rem', // optical center
            paddingLeft: '2rem',
            paddingRight: '2rem',
            margin: '0 auto'
          }}
        >
          <h1 style={{ color: Style.COLOR.GREY_900 }}>Something went wrong</h1>

          <p style={{ color: Style.COLOR.GREY_800 }}>
            Sorry, we hate errors too! Luckily, we've just been notified about
            this one and should have a fix soon. In the mean time, please click
            the button below to go back to safety.
          </p>

          <ButtonDefault style={{ width: '100%' }} onClick={this.handleReload}>
            Take me back to safety
          </ButtonDefault>

          {process.env.NODE_ENV === 'development' && (
            <div>
              <p style={{ color: Style.COLOR.GREY_800, fontSize: 13 }}>
                Developers, see your browser console for more information about
                this error.
              </p>
            </div>
          )}
        </div>
      )
    }

    return this.props.children
  }
}
