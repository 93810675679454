import { useContext } from 'react'
import { OverlayContext } from './context-provider'

const Modal: React.FC = () => {
  const context = useContext(OverlayContext)
  return context.state.isVisible ? (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        zIndex: 100
      }}
    >
      {context.state.content}
    </div>
  ) : null
}

export default Modal
