import styled from '@emotion/styled'
import { ChevronLeft } from '@util/icons'
import * as Style from '../style'

export { ReactComponent as Logo } from '@util/svgs/logo.svg'
export { mediaBreakpointOnly, mediaBreakpointUp } from './breakpoints'
export { default as Button } from './button'
export { default as ButtonDefault } from './button-default'
export { default as ButtonPrimary } from './button-primary'
export { default as CloseButton } from './close-button'
export { default as Container } from './container'
export { default as Dropdown } from './dropdown'
export { default as Fab } from './fab'
export { default as LinkPrimary } from './link-primary'
export { default as MobileBlockNotice } from './mobile-block-notice'
export { default as ModalAlert } from './modal-alert'
export { default as SpinnerOverlay } from './spinner-overlay'
export { default as TextInput } from './text-input'
export { default as Title } from './title'
export { default as useBackButtonAction } from './use-back-button-action'
export { default as useDynamicState } from './use-dynamic-state'
export { default as useLoader } from './use-loader'
export { default as useTextField } from './use-text-field'
export { default as useTrackView } from './use-track-view'
export { default as useTrackViewAnonymous } from './use-track-view-anonymous'
export { default as useUrlPath } from './use-url-path'
export { default as useUserSync } from './use-user-sync'
export { default as UserRecipeImage } from './user-recipe-image'

export const Body = styled.div({
  color: Style.COLOR.GREY_800,
  fontSize: '1rem',
  lineHeight: 1.5
})

export const Link = styled.a({
  color: '#999',
  transition: '0.25s ease color',

  ':hover': {
    color: Style.COLOR.GREEN_500
  },

  ':focus, :active': {
    color: Style.COLOR.GREY_900
  }
})

// BackButton, Navbar, Scene components deprecated!
// They will be removed when the registration/handoff scenes are removed

export function BackButton(props: { onClick: () => void }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        color: Style.COLOR.GREEN_500,
        fontSize: '1rem',
        cursor: 'pointer'
      }}
      onClick={props.onClick}
    >
      <ChevronLeft /> Back
    </div>
  )
}

export const NavBar = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: 40
})

export const Scene = styled.div({
  flex: 1,
  maxWidth: 600,
  width: '100%',
  margin: '0 auto',
  padding: Style.GUTTER_WIDTH,
  display: 'flex',
  flexDirection: 'column',
  /* Absolutely positioned elements should respect scene bounds. */
  position: 'relative'
})
