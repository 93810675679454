import { config } from './common'
import * as dev from './dev'

// Do not place secrets in config
// All code is shipped to the browser

if (process.env.NODE_ENV === 'development') {
  dev.init(config)
}

export default config
