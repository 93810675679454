import styled from '@emotion/styled'
import * as Style from '@util/style'

export default styled.a({
  color: Style.COLOR.GREEN_700,
  transition: 'color 0.2s ease',

  ':hover': {
    color: '#0f6a0e'
  },

  ':active': {
    color: '#062b06'
  }
})
