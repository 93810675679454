import { useEffect } from 'react'

export default function useUrlPath(path: string) {
  useEffect(() => {
    const previousPath = document.location.pathname
    window.history.replaceState(
      window.history.state,
      document.title,
      '/' + path
    )
    return () => {
      window.history.replaceState(
        window.history.state,
        document.title,
        previousPath
      )
    }
  }, [path])
}
