import { useEffect } from 'react'
import * as Stats from '../stats'
import { IUser } from '../types'

export default function useTrackView(
  user: IUser,
  code: string,
  properties?: any
) {
  useEffect(() => {
    Stats.track(user, code, properties)
  }, [user, code])
}
