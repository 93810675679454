export default class BrowserStore {
  constructor(private key: string) {}

  get = (): string | undefined => {
    const value = localStorage.getItem(this.key)

    if (value === null) {
      return
    } else {
      return value
    }
  }

  set = (value: string) => {
    localStorage.setItem(this.key, value)
  }

  clear = () => {
    localStorage.removeItem(this.key)
  }
}
