import { Global, Interpolation } from '@emotion/core'
import * as Style from '@util/style'

const STYLES: Interpolation = {
  '*, *::before, *::after': {
    boxSizing: 'border-box'
  },

  'html, body, #root': {
    height: '100%'
  },

  html: {
    textSizeAdjust: '100%',
    fontSize: 16
  },

  body: {
    fontFamily: Style.FONT_FAMILY_SAN_SERIF,
    minHeight: '100%',
    overflowX: 'hidden',
    // font smoothing needs to be manually vendor prefix and
    // will not convert to camelCase.
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    textRendering: 'optimizeLegibility'
  },

  strong: {
    fontWeight: 'bold'
  },

  a: {
    cursor: 'pointer'
  },

  'a[type="button"]': {
    WebkitAppearance: 'none'
  },

  svg: {
    display: 'inline-block'
  },

  // Prevents cursor flicking and firefox bug with svgs in links not working
  'a > svg, button > svg': {
    pointerEvents: 'none'
  },

  button: {
    userSelect: 'none',
    outline: 'none'
  },

  // Use a pointer cursor unless the button is disabled
  'button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled)': {
    cursor: 'pointer'
  },

  img: {
    verticalAlign: 'middle',
    borderStyle: 'none'
  }
}

export default function GlobalStyles() {
  return <Global styles={STYLES} />
}
