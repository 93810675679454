import type { IRoute, ISceneImport } from './types'
import { makeRoute } from './util'

export default class Navigator {
  constructor(private setRoute: (route: IRoute) => void) {}

  go = async <T>(sceneImport: ISceneImport<T>, props: T) => {
    const route = await makeRoute(sceneImport, props)
    this.setRoute(route)
  }
}
