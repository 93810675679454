import styled from '@emotion/styled'
import useOverlay from '@lib/overlay/use-overlay'
import * as Style from '@util/style'
import { ButtonDefault } from '@util/ui'
import useDismissableRef from '@util/ui/use-dismissable-ref'
import { CSSProperties, useRef } from 'react'
import CloseButton from './close-button'

interface IProps {
  type?: 'success' | 'error'
  title: string
  message?: string
  icon?: JSX.Element
  buttonLabel?: string
  style?: CSSProperties
  onClose?: () => void
}

const ErrorModal: React.FC<IProps> = ({
  type,
  title,
  message,
  icon,
  buttonLabel,
  style,
  onClose
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const overlay = useOverlay()

  useDismissableRef(ref, () => {
    overlay.hide()
  })

  function handleClose() {
    overlay.hide()
    onClose && onClose()
  }

  function getTypeColors() {
    if (type === 'success') {
      return {
        icon: Style.COLOR.GREEN_500,
        background: Style.COLOR.GREEN_200
      }
    }
    if (type === 'error') {
      return {
        icon: Style.COLOR.RED_500,
        background: '#f9eded'
      }
    }

    return {
      icon: Style.COLOR.YELLOW_500,
      background: '#fefbe7'
    }
  }

  return (
    <div
      style={{
        display: 'grid',
        placeItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10,
        height: '100vh',
        width: '100vw',
        backgroundImage:
          'linear-gradient(207deg, rgba(248,248,248,0.75) 0%, rgba(217,217,217,0.75) 33%, rgba(214,214,214,0.75) 66%, rgba(248,248,248,0.75) 100%)'
      }}
    >
      <CloseButtonWrapper>
        <CloseButton onClick={handleClose} />
      </CloseButtonWrapper>
      <div
        ref={ref}
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 3fr',
          maxWidth: '29rem',
          borderRadius: 8,
          backgroundColor: '#fff',
          boxShadow: '0 12px 40px -12px rgba(0, 0, 0, 0.33)',
          overflow: 'hidden',
          ...style
        }}
      >
        {!!icon ? (
          <div
            style={{
              display: 'grid',
              placeItems: 'center',
              minHeight: '12rem',
              paddingTop: '2rem',
              paddingBottom: '1.5rem',
              paddingLeft: '2rem',
              paddingRight: '2rem',
              backgroundColor: getTypeColors().background
            }}
          >
            <div
              css={{
                color: getTypeColors().icon,
                svg: { width: 64, height: 64 }
              }}
            >
              {icon}
            </div>
          </div>
        ) : (
          <div
            style={{ width: '0.25rem', backgroundColor: getTypeColors().icon }}
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '2rem',
            paddingBottom: '1.5rem',
            paddingLeft: '2rem',
            paddingRight: '2rem'
          }}
        >
          {!!title && (
            <div
              style={{
                color: Style.COLOR.GREY_800,
                fontSize: '1.15rem',
                fontWeight: 600,
                // textAlign: 'center',
                letterSpacing: '-0.01em'
              }}
            >
              {title}
            </div>
          )}

          {!!message && (
            <div
              style={{
                color: Style.COLOR.GREY_700,
                // fontSize: '0.875rem',
                lineHeight: 1.4,
                // textAlign: 'center',

                marginTop: '0.75rem'
              }}
            >
              {message}
            </div>
          )}
          <div
            style={{
              marginTop: '2.5rem',
              marginLeft: 'auto'
            }}
          >
            <ButtonDefault type="button" onClick={handleClose}>
              {buttonLabel || 'Okay'}
            </ButtonDefault>
          </div>
        </div>
      </div>
    </div>
  )
}

const CloseButtonWrapper = styled.div({
  position: 'absolute',
  top: '2rem',
  left: '2rem'
})

export default ErrorModal
