import { useEffect } from 'react'

const useDismissableRef = (ref: any, callback: () => void) => {
  const escapeListener = (event: KeyboardEvent) => {
    if (ref.current && event.key === 'Escape') {
      callback()
    }
  }

  const clickListener = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', clickListener)
    document.addEventListener('keyup', escapeListener)
    return () => {
      document.removeEventListener('click', clickListener)
      document.removeEventListener('keyup', escapeListener)
    }
  }, [])
}

export default useDismissableRef
