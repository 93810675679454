// Enable emotion's "css" prop.
import '@emotion/core'
// Enable normalize CSS defaults.
import 'normalize.css'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from './app'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
