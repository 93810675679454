import * as RandomId from '../random-id'
import type { IRoute, IScene, ISceneImport } from './types'

export async function makeRoute<T>(
  sceneImport: ISceneImport<T>,
  params: T
): Promise<IRoute<T>> {
  const scene = (await sceneImport).default
  return makeRouteStatic(scene, params)
}

export function makeRouteStatic<T>(scene: IScene<T>, params: T) {
  return { scene, params, key: RandomId.make() }
}
