import { useState } from 'react'
import Navigator from './navigator'
import type { IRoute } from './types'

interface IProps {
  initialRoute: IRoute
}

export default function Router(props: IProps) {
  const [route, setRoute] = useState(props.initialRoute)

  const navigator = new Navigator(setRoute)

  return <route.scene params={route.params} navigator={navigator} />
}
