import styled from '@emotion/styled'
import * as Style from '@util/style'
import { Button } from '@util/ui'
import { useEffect } from 'react'

interface IProps {
  onClick: () => void
}

const CloseButton: React.FC<IProps> = ({ onClick }) => {
  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.key === 'Escape') {
        onClick()
      }
    }

    window.addEventListener('keyup', handleEsc)
    // clean up event listener
    return () => {
      window.removeEventListener('keyup', handleEsc)
    }
  }, [])

  return (
    <Wrapper>
      <Button onClick={onClick}>Close</Button> <span>Esc</span>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'inline-flex',
  alignItems: 'baseline',

  '& > span': {
    fontSize: '0.8rem',
    color: Style.COLOR.GREY_700,
    marginLeft: '0.5rem',
    opacity: 0,
    transition: 'opacity 0.15s ease'
  },

  ':hover > span': {
    opacity: 1
  }
})

export default CloseButton
