import { createContext, useReducer } from 'react'
import { contextReducer } from './context-reducer'
import { IContext, IContextAction, IContextState } from './types'

function getDefaultState(): IContextState {
  return { isVisible: false, content: <div /> }
}

const OverlayContext = createContext<IContext>({
  state: getDefaultState(),
  dispatch: () => {
    // eslint placeholder
  }
})

const OverlayContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer<
    React.Reducer<IContextState, IContextAction>
  >(contextReducer, getDefaultState())

  return (
    <OverlayContext.Provider value={{ state, dispatch }}>
      {children}
    </OverlayContext.Provider>
  )
}

export { OverlayContext, OverlayContextProvider }
