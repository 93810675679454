import { useState } from 'react'

export default function useLoader() {
  const [isLoading, setLoading] = useState(false)

  return {
    isLoading,
    start: () => {
      setLoading(true)
    },
    stop: () => {
      setLoading(false)
    },
    run: async <T>(fn: () => Promise<T>) => {
      try {
        setLoading(true)
        const result = await fn()
        return result
      } finally {
        setLoading(false)
      }
    }
  }
}
